frappe.list_view_headers = {
    add_header_card: function (page_header, title, value, color) {
        let card_html = `
        <div class="card col-lg-3 col-md-4 col-sm-6" style=" border-top: 4px solid #afafaf;max-width:23%;margin-left:25px;height:90px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
            <div class="card-body">
                <h5 class="card-title">${title}</h5>
                <p class="indicator-pill ellipsis ${color}">${value}</p>
            </div>
        </div>`
        page_header.append(card_html)
    },

    setup_header: function (page, cards) {
        let page_header = $(
            "div[id*='" + page + "\\/List'] > .page-body > .page-toolbar",
        );
        page_header.removeClass("hide");
        page_header = $(
            "div[id*='" +
            page +
            "\\/List'] > .page-body > .page-toolbar > .container",
        );
        page_header
            .addClass("row")
            .css("padding-bottom", "20px")
            .css("width", "100%")
        page_header = page_header.empty();

        cards.forEach((card) => {
            this.add_header_card(
                page_header,
                card.title,
                card.value,
                card.color
            );

            if (card.filters) {
                let title = $('h5.card-title').filter(function () {
                    return $(this).html() === card.title;
                });
                
                title.find('span').each(function () {
                    let text = $(this).text();

                    $(this).css("cursor","pointer")

                    $(this).hover(
                        function () {
                            $(this).css("color", "#2490ef");
                        },
                        function () {
                            $(this).css("color", "");
                        }
                    );

                    $(this).click(
                        { page: page, filter: card.filters[text] },
                        handle_card_click
                    )
                });
            }
        });
    },
}

function handle_card_click(e) {
    var route = e.data.filter;
    let query_string = frappe.utils.get_query_params();
    if (query_string) {
        route = Object.assign(query_string, route);
    }
    console.log(route)
    route =
        "?" +
        String(
            Object.entries(route)
                .map(([key, val]) => `${key}=${val}`)
                .join("&"),
        );
    console.log(route)
    return new Promise((resolve) => {
        frappe.router.push_state(route);
        setTimeout(() => {
            frappe.after_ajax &&
                frappe.after_ajax(() => {
                    resolve();
                });
        }, 100);
    }).finally(() => (frappe.route_flags = {}));
}