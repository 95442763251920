frappe.bulk_upload = {
    setup: function(doctype, default_template = ""){
        let _d = new frappe.ui.Dialog({
            title: "Bulk Upload Form",
            fields: [
                {
                    label: "Select Template",
                    fieldname: "template_name",
                    fieldtype: "Link",
                    options: "Bulk Import Templates",
                    default: default_template
                },
                {
                    label: "Upload Doc",
                    fieldname: "file_url",
                    fieldtype: "Attach"
                }
            ],
            primary_action_label: "Submit",
            primary_action(values) {
                let template = values.template_name;
                let file_url = values.file_url
                frappe.call({
                    method: "frappe_utils.frappe_utils.doctype.bulk_import_templates.bulk_import_templates.bulk_create_doc",
                    args: {
                        doctype: doctype,
                        template_name: template,
                        file_url: file_url
                    },
                    callback: function (resp) {
                        frappe.dom.unfreeze()
                    }
                })
                _d.hide()
            }
        })
        _d.show();
    }
}